import { combineReducers } from 'redux';
import projectTaskReducer from './projectTaskReducer';
import userReducer from './userReducer';
// Import and combine other reducers as needed

const rootReducer = combineReducers({
  project: projectTaskReducer,
  user: userReducer
  // Add other reducers here
});

export default rootReducer;
