
import { Col, Row, Spinner, Button } from "react-bootstrap";
import { HeadingFour, HeadingThree, PTag, MyDiv } from "../Common/Components";
import TextField from '@mui/material/TextField';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import Slider from 'react-slick';
import { api } from "../Common/Constants/apiurl";
import axios from 'axios';
import swal from "sweetalert2";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const API_BASE_URL = api.API_BASE_URL;
function CoreForgotPassword() {
  const navigate = useNavigate();
  const [btnLoading, setbtnLoading] = useState(false);
  const [data, setData] = useState({
    email_id: ""
  })
  function submit(e) {
    e.preventDefault();
    setbtnLoading(true)
    var url = API_BASE_URL + 'forgotpassword';
    var method = 'post';
    axios({ method, url, data })
      .then(res => {
        if (res.status === 200) {
          swal.fire({
            text: "Password Reset Link Sent to your Email",
            icon: "success",
            type: "success"
          });
          setbtnLoading(false);
          navigate("/dashboard");
          //handleClose();
        }
      })
      .catch(res => {
    
        swal.fire({
          text: res.response.data,
          icon: "error",
          type: "error"
        });
        setbtnLoading(false);

      });
  }
  const settings = {
    dots: true,
    infinite: false,
    navs: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function handle(e) {
    const newdata = { ...data }
    newdata[e.target.id] = e.target.value
    setData(newdata)
  }
  return (
    <MyDiv>
      <Row className="row GeneralHeading login-form">
        <MyDiv className="left">
          <Col md={6}>
            <form onSubmit={(e) => submit(e)}>
              <img src={require('../../assets/images/logo.png')} alt="logo" />
              <HeadingFour className="card-title  login-head">
                Internal HRMS Application<br /><br />
                Forgot password
              </HeadingFour>
              <Col md={12} className="forgotfield">
                <TextField required id="email_id" className="width-100" label="Email Id" value={data.email_id} variant="standard" onChange={(e) => handle(e)} />
              </Col>
              <Col md={12}>
                {btnLoading ?
                  <Button variant="primary" disabled className='mt-4 w-100'>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mx-2' />
                    Loading...
                  </Button> :
                  <button type="submit" className="btn primary-btn add-cta w-100 mt-5">SUBMIT</button>
                }
              </Col>
              <Col md={12} className="text-end">
                <Link to="/login" className="mt-15  d-block">Back to Login</Link>
              </Col>
            </form>

          </Col>
          <Col md={6} className="right-slide">
            <Slider {...settings}>
              <MyDiv className="slide">
                <img src={require('../../assets/images/employee.png')} alt="icon" />
                <HeadingThree className="mt-3 mb-3">Dashboard</HeadingThree>
                <PTag>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure, odio! consectetur adipisicing elit. Iure, odio!</PTag>
              </MyDiv>
              <MyDiv className="slide">
                <img src={require('../../assets/images/icon.png')} alt="icon" />
                <HeadingThree className="mt-3 mb-3">Employee</HeadingThree>
                <PTag>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure, odio! consectetur adipisicing elit. Iure, odio!</PTag>
              </MyDiv>
              <MyDiv className="slide">
                <img src={require('../../assets/images/employee.png')} alt="icon" />
                <HeadingThree className="mt-3 mb-3">Salary</HeadingThree>
                <PTag>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure, odio! consectetur adipisicing elit. Iure, odio!</PTag>
              </MyDiv>
            </Slider>
          </Col>
        </MyDiv>
      </Row>
    </MyDiv>
  );
}

export default CoreForgotPassword;