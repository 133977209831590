import * as React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import ManageNewProjects from "./manage";
import NewDetailProject from "./details";
import MyTask from "./MyTask";

export default function NewProjectsCore() {
  return (
    <>
    <Routes>
      <Route path="/" element={<NewProjectsLayout />}>
        <Route index path="" element={<ManageNewProjects />} />
             <Route path="/:id" element={<NewDetailProject/>} />
             <Route path="/my-task" element={<MyTask />} />
      </Route>
    </Routes>
    <Outlet />
    </>
  );
}
function NewProjectsLayout() {
  return (
    <>
      <Outlet />
    </>
  );
}