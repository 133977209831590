import React, { useState, useEffect } from 'react';
import { HeadingFour, MyDiv } from "../Common/Components";
import { Row, Col, Card, Spinner, Button } from "react-bootstrap";
import { TextField, MenuItem, Autocomplete } from '@mui/material';
import axios from 'axios';
import { DevelopmentTeam, GRDevelopmentTeam } from '../Common/Constants/staticjson';
import swal from "sweetalert2";
import { api } from "../Common/Constants/apiurl";
import moment from 'moment';

const API_BASE_URL = api.API_BASE_URL;

function ProjectTimeSheetForm({ handleClose, taskEditId }) {
    const API_TOKEN = localStorage.getItem("token");
    const [projectList, setProjects] = useState([]);
    const [ticketNumber, setTicketNumber] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [data, setData] = useState({
        tms_Task_Project_Obj: "",
        tms_Task_Desc: "",
        tms_Task_Brand: "",
        tms_Task_Department: "",
        tms_Task_Entry_Date: "",
        tms_Task_Worked_Hours: "",
    })

    useEffect(() => {
        if (taskEditId?.id !== undefined) {
            loadSpecificTimeSheet();
        }
        else {
            //const [data, setData] = "";
        }
    }, []);

    const loadSpecificTimeSheet = async () => {
        var url = API_BASE_URL + `fetch-emp-daily-task-to-edit/` + taskEditId?.id;
        axios.get(url, { headers: { 'x-access-token': `${API_TOKEN}`, 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(res => {
                const specificTicketTimeSheet = res.data[0];
                specificTicketTimeSheet.tms_Task_Entry_Date = specificTicketTimeSheet.tms_Task_Entry_Date ? moment(specificTicketTimeSheet.tms_Task_Entry_Date).format('YYYY-MM-DD') : null;
                setData(res.data[0]);
            },
                (error) => {
                    swal.fire({
                        text: error.response.data,
                        icon: "error",
                        type: "error"
                    });
                })
    }

    const loadProjects = async () => {
        var url = API_BASE_URL + `fetchprojects`;
        axios.post(url, {}, { headers: { 'x-access-token': `${API_TOKEN}`, 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(res => {
                setProjects(res.data)
            },
                (error) => {
                    swal.fire({
                        text: error.response.data,
                        icon: "error",
                        type: "error"
                    });
                })
    }
    useEffect(() => {
        loadProjects();
        loadAllTickets();
    }, []);


    const loadAllTickets = async () => {
        var url = API_BASE_URL + `fetch-tickets-to-task`;
        axios.get(url, { headers: { 'x-access-token': `${API_TOKEN}`, 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(res => {
                setTicketNumber(res.data)
            },
                (error) => {
                    swal.fire({
                        text: error.response.data,
                        icon: "error",
                        type: "error"
                    });
                })
    }

    function handle(e) {
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value
        setData(newdata)
    }
    const handleChangeWhiteSpace = (e) => {
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value;
        setData(newdata)
    };

    const handleFieldChange = event => {
        setData(data => ({
            ...data,
            [event.target.name]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value
        }));
    };
    const handleTicketNoFieldChange = (event, brand) => {
        console.log('event', event.target.dataset.value, 'brand', brand)
        const newdata = {
            ...data,
            ["tms_Task_Project_Obj"]: event.target.dataset.value,
            ["tms_Task_Brand"]: brand
        };
        setData(newdata);
    };

    function submit(e) {
        e.preventDefault();
        setBtnLoading(true);
        var url = taskEditId?.id ? API_BASE_URL + 'update-emp-daily-task/' + taskEditId?.id : API_BASE_URL + 'add-daily-task-details';
        var method = taskEditId?.id ? 'patch' : 'post';
        axios({ method, url, data, headers: { 'x-access-token': `${API_TOKEN}`, 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.status === 200) {
                    swal.fire({
                        text: "Successfully Saved",
                        icon: "success",
                        type: "success"
                    });
                    handleClose();
                }
                setBtnLoading(false);
            })
            .catch(error => {
                swal.fire({
                    text: error.response.data,
                    icon: "error",
                    type: "error"
                });
            });
    }

    const [taskMode, setTaskMode] = useState('');
    useEffect(() => {
        if (taskEditId && taskEditId.tms_Task_Brand === "Service Project") {
            setTaskMode('Project TimeSheet');
        } else {
            setTaskMode('Ticket TimeSheet');
        }
    }, [taskEditId]);

    const handleTaskModeChange = (event) => {
        setTaskMode(event.target.value);
        const newdata = {
            ...data,
            ["tms_Task_Brand"]: "Service Project"
        };
        setData(newdata);
    };

    return (
        <MyDiv className="DrawerRight">
            <MyDiv className="grid-margin general-form stretch-card">
                <Card md={12}>
                    <Card.Header>
                        <HeadingFour className="card-title">
                            {taskEditId ? "Edit" : "Add"} TimeSheet
                        </HeadingFour>
                    </Card.Header>
                    <Card.Body>
                        <MyDiv className="DrawerForm">
                            <form onSubmit={(e) => submit(e)}>
                                <Row className="DrawerFormField">
                                    <Col md={12}>
                                        <TextField select variant="outlined" disabled={taskEditId?.id} label="Select Task Mode" value={taskMode} onChange={handleTaskModeChange}>
                                            <MenuItem value="Project TimeSheet">Internal Project TimeSheet</MenuItem>
                                            <MenuItem value="Ticket TimeSheet">GR Ticket TimeSheet</MenuItem>
                                        </TextField>
                                    </Col>
                                    {taskMode === 'Project TimeSheet' && (<>
                                        <Col md={12}>
                                            <Autocomplete
                                                id="tms_Task_Project_Obj"
                                                options={projectList}
                                                value={projectList.find(project => project._id === data.tms_Task_Project_Obj) || null}
                                                getOptionLabel={(option) => option.pro_name}
                                                onChange={(event, newValue) => {
                                                    setData((prevData) => ({
                                                        ...prevData,
                                                        ["tms_Task_Project_Obj"]: newValue?._id
                                                    }));
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        required
                                                        {...params}
                                                        variant="outlined"
                                                        label="Search/Select Project"
                                                        placeholder="Search/Select"
                                                        onChange={(event) => {
                                                            if (event.target.value !== '' || event.target.value !== null) {
                                                                console.log(event.target.value)
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <TextField select required name="tms_Task_Department" id="tms_Task_Department" variant="outlined" label="Department" SelectProps={{ value: data.tms_Task_Department, onChange: handleFieldChange }} >
                                                {DevelopmentTeam.map((Team, index) => (
                                                    <MenuItem key={index} value={Team.value}>{Team.label}</MenuItem>
                                                ))}
                                            </TextField>
                                        </Col></>)}
                                    {taskMode === 'Ticket TimeSheet' && (<>
                                        <Col md={12}>
                                        <Autocomplete
                                                id="tms_Task_Project_Obj"
                                                options={ticketNumber}
                                                 value={ticketNumber.find(project => project._id === data.tms_Task_Project_Obj) || null}
                                                getOptionLabel={(option) => option.tms_Ticket_No}
                                                onChange={(event, newValue) => {
                                                    setData((prevData) => ({
                                                        ...prevData,
                                                        ["tms_Task_Project_Obj"]: newValue?._id,
                                                        ["tms_Task_Brand"]: newValue?.tms_Ticket_Brand
                                                    }));
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        required
                                                        {...params}
                                                        variant="outlined"
                                                        label="Search/Select Ticket"
                                                        placeholder="Search/Select"
                                                        onChange={(event) => {
                                                            if (event.target.value !== '' || event.target.value !== null) {
                                                                console.log(event.target.value)
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <TextField disabled required id="tms_Task_Brand" label="Ticket Brand" value={data.tms_Task_Brand ? data.tms_Task_Brand : ""} variant="outlined" />
                                        </Col>
                                        <Col md={12}>
                                            <TextField select required name="tms_Task_Department" id="tms_Task_Department" variant="outlined" label="GR Department" SelectProps={{ value: data.tms_Task_Department, onChange: handleFieldChange }} >
                                                {GRDevelopmentTeam.map((Team, index) => (
                                                    <MenuItem key={index} value={Team.value}>{Team.label}</MenuItem>
                                                ))}
                                            </TextField>
                                        </Col></>)}
                                    <Col md={12}>
                                        <TextField required id="tms_Task_Desc" label="Task Description" multiline rows={4} value={data.tms_Task_Desc} variant="outlined" onChange={(e) => handleChangeWhiteSpace(e)} />
                                    </Col>
                                    <Col md={12}>
                                        <TextField id="tms_Task_Entry_Date" label="Worked Date" type="date" value={data.tms_Task_Entry_Date} sx={{ width: 220 }} onChange={(e) => handle(e)} InputLabelProps={{ shrink: true, }} variant="outlined" />
                                    </Col>
                                    <Col md={12}>
                                        <TextField required id="tms_Task_Worked_Hours" label="Worked Hours" value={data.tms_Task_Worked_Hours} variant="outlined" onChange={(e) => handleChangeWhiteSpace(e)} />
                                    </Col>
                                    <Col md={12} className="text-end">
                                        {btnLoading ?
                                            <Button variant="primary" disabled className='mt-4 w-100'>
                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mx-2' />
                                                Loading...
                                            </Button> :
                                            <button className="FormBtn">Submit</button>
                                        }


                                    </Col>
                                </Row>
                            </form>
                        </MyDiv>
                    </Card.Body>
                </Card>
            </MyDiv>
        </MyDiv>
    );
}
export default ProjectTimeSheetForm;