import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Row, Card, Tab, Tabs } from "react-bootstrap";
import { HeadingFour, MyDiv, LabelTag, SpanTag, StrongTag, PTag, } from "../Common/Components";
import { api } from "../Common/Constants/apiurl";
import Avatar from "react-avatar";
import axios from 'axios';
import { IconButton, Drawer, Tooltip, TableBody, Table, TableHead, TableRow, TableCell, } from "@mui/material";
import { Edit } from "@mui/icons-material";
import moment from 'moment';
import NoDataFound from '../Common/nodatafound';
import FormProjects from './form';
import swal from "sweetalert2";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { GiHistogram } from "react-icons/gi";
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjectAndLeads, fetchProjectResources } from '../ReduxToolkit/reducer/projectTaskReducer';
import ProjectTasks from './Projectstasks';
import EstimateForm from './EstimateForm';
import ProjectNotes from './ProjectNotes';

const API_BASE_URL = api.API_BASE_URL;
const Rolename = localStorage.getItem("role-name");

const CustomTableRow = ({ label, item, category }) => {
    if (category === "Development") {
        category = "dev";
    }
    if (category === "ProjectManagement") {
        category = "pm";
    }
    const renderWorkedData = (data) => (
        Array.isArray(data) ? (
            data.map((entry, index) => (
                <React.Fragment key={index}>
                    <PTag className="mb-1">{entry}</PTag>
                </React.Fragment>
            ))
        ) : null
    );
    return (
        <TableRow>
            <TableCell align="left">
                <LabelTag>{label}</LabelTag>
            </TableCell>
            <TableCell align="center">
                <SpanTag>{item[`${category.toLowerCase()}_pro_planned_start_date`] ? moment(item[`${category.toLowerCase()}_pro_planned_start_date`]).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag>
            </TableCell>
            <TableCell align="center">
                <SpanTag>{item[`${category.toLowerCase()}_pro_planned_end_date`] ? moment(item[`${category.toLowerCase()}_pro_planned_end_date`]).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag>
            </TableCell>
            <TableCell align="center">
                <SpanTag>{item[`${category.toLowerCase()}_pro_actual_start_date`] ? moment(item[`${category.toLowerCase()}_pro_actual_start_date`]).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag>
            </TableCell>
            <TableCell align="center">
                <SpanTag>{item[`${category.toLowerCase()}_pro_actual_end_date`] ? moment(item[`${category.toLowerCase()}_pro_actual_end_date`]).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag>
            </TableCell>
            <TableCell align="center">
                <SpanTag className="multipleName">
                    {renderWorkedData(item[`worked_${category.toLowerCase()}`])}
                </SpanTag>
            </TableCell>
            <TableCell align="center">
                <SpanTag>{item[`${category.toLowerCase()}_pro_estimated_hours`]}</SpanTag>
            </TableCell>
            <TableCell align="center">
                <SpanTag>{item[`${category.toLowerCase()}_pro_actual_hours`]}</SpanTag>
            </TableCell>
            <TableCell align="center">
                <StrongTag className={+ (parseInt(item[`${category.toLowerCase()}_pro_estimated_hours`], 10) - parseInt(item[`${category.toLowerCase()}_pro_actual_hours`], 10)) >= 0 ? "text-success" : "text-danger"}>
                    {parseInt(item[`${category.toLowerCase()}_pro_estimated_hours`], 10) - parseInt(item[`${category.toLowerCase()}_pro_actual_hours`], 10)}
                </StrongTag>


                <SpanTag>{item[`${category.toLowerCase()}_pro_deviation_hours`]}</SpanTag>
            </TableCell>
        </TableRow>
    )
};


function NewDetailProject() {
    const API_TOKEN = localStorage.getItem("token");
    const dispatch = useDispatch();
    let { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState('');
    const [projectDetails, setProjectDetails] = useState([]);
    const [estimatDrawerState, setEstimatDrawerState] = useState(false);
    const [deptEstData, setDeptEstData] = useState({});
    const loggedEmpId = localStorage.getItem("userId");
    useEffect(() => {
        setLoading(true);
    }, [dispatch]);

    const [module, setModule] = React.useState([{
        ProjectManagement: '',
    }
    ])

    useEffect(() => {
        FetchProjectDetails();
        async function init() {
            const data = await localStorage.getItem('role');
            setModule(JSON.parse(data));
        }
        init();
    }, []);

    const FetchProjectDetails = async () => {
        var url = API_BASE_URL + `fetch-specific-project-details/` + id;
        axios.get(url, { headers: { 'x-access-token': `${API_TOKEN}`, 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(res => {
                setProjectDetails(res.data);
                setLoading(false);
            },
                (error) => {
                    swal.fire({
                        text: error.response.data,
                        icon: "error",
                        type: "error"
                    });
                })
    }

    //Project basic Details
    const [projectDrawerState, setProjectsDrawerState] = React.useState(false);
    const handleProjectsDrawerToggle = (props) => {
        setData();
        projectDrawerState === false ? setProjectsDrawerState(true) : setProjectsDrawerState(false)
    }
    const projectEditId = (_id) => {
        projectDrawerState === false ? setProjectsDrawerState(true) : setProjectsDrawerState(false)
        setData(_id);
    }
    const updateDrawer = () => {
        projectDrawerState === false ? setProjectsDrawerState(true) : setProjectsDrawerState(false)
        // loadProject();
        dispatch(fetchProjectAndLeads(id));
    };


    const handleEstimatDrawerToggle = (deptData) => {
        setDeptEstData(deptData);
        setEstimatDrawerState(!estimatDrawerState);
    };

    const updateEstimatDrawer = () => {
        console.log('hited')
        setEstimatDrawerState(!estimatDrawerState);
        FetchProjectDetails();

    };

    let navigate = useNavigate();
    // const moduleMilestone = "MilestoneTask";
    const moduleProject = "ProjectTask";

    const categories = ['Development', 'QC', 'Design', 'SEO', 'ProjectManagement'];

    return (
        <>  {loading ? (
            <HeadingFour className="text-center">Loading...</HeadingFour>) : <>
            {projectDetails?.length
                ? projectDetails.map((item) => (
                    <React.Fragment key={item._id}>
                        <Row>
                            <Col md={12} className="mb-4">
                                <MyDiv className="ProfileBasicDetail user-profile">
                                    <Card className="ProfileBasicDetailLeftHeader" >
                                        <Row>
                                            <Link className="arrow-btn" onClick={() => navigate(-1)}><MdOutlineKeyboardArrowLeft /></Link>
                                            <Col md={2} className="text-center">
                                                <Avatar
                                                    round="100px"
                                                    size="120"
                                                    name={item.pro_name}
                                                />
                                                <HeadingFour>{item.pro_name}</HeadingFour>
                                            </Col>
                                            <Col md={4} className="text-start">
                                                <MyDiv className="ProfileCard">
                                                    <Row>
                                                        <Col xs={5}><LabelTag>Project Code</LabelTag></Col>
                                                        <Col xs={7}><SpanTag>{item.pro_code}</SpanTag></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={5}><LabelTag>Customer Name</LabelTag></Col>
                                                        <Col xs={7}><SpanTag>{item.customer_name}</SpanTag></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={5}><LabelTag>Start Date</LabelTag></Col>
                                                        <Col xs={7}><SpanTag>{item.pro_startDate}</SpanTag></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={5}><LabelTag>End date</LabelTag></Col>
                                                        <Col xs={7}><SpanTag>{item.pro_endDate}</SpanTag></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={5}><LabelTag>Cost Center</LabelTag></Col>
                                                        <Col xs={7}><SpanTag>{item.pro_costCenter}</SpanTag></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={5}><LabelTag>Project Owner</LabelTag></Col>
                                                        <Col xs={7}>
                                                            <SpanTag>
                                                                {item.project_owner}                                                                 {/* {item.approversData.map((data, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {index > 0 && ", "} 
                                                                        {data.emp_firstName}
                                                                    </React.Fragment>
                                                                ))} */}
                                                            </SpanTag>
                                                        </Col>
                                                    </Row>
                                                </MyDiv>
                                            </Col>
                                            <Col md={4} className="text-start">
                                                <MyDiv className="ProfileCard">
                                                    <Row>
                                                        <Col xs={5}><LabelTag>Project Type</LabelTag></Col>
                                                        <Col xs={7}><SpanTag>{item.pro_type}</SpanTag></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={5}><LabelTag>Overall Planned Hours</LabelTag></Col>
                                                        <Col xs={7}><SpanTag>{item.pro_totalHours}</SpanTag></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={5}><LabelTag>Project Status</LabelTag></Col>
                                                        <Col xs={7}><SpanTag>{item.pro_status}</SpanTag></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={5}><LabelTag>Contract Status</LabelTag></Col>
                                                        <Col xs={7}><SpanTag>{item.pro_contractStatus}</SpanTag></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={5}><LabelTag>Contract Renew Date</LabelTag></Col>
                                                        <Col xs={7}><SpanTag>{item.pro_contractRenewDate}</SpanTag></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={5}><LabelTag>Project Description</LabelTag></Col>
                                                        <Col xs={7}><SpanTag>{item.pro_description}</SpanTag></Col>
                                                    </Row>
                                                </MyDiv>
                                            </Col>

                                            <Col md={2} className='text-end'>
                                                {(module.ProjectManagement && module.ProjectManagement.edit === "1") ? 
                                                <Tooltip title="Edit">
                                                    <IconButton aria-label="fingerprint" color="success" onClick={(e) => projectEditId(item._id)}>
                                                        <Edit />
                                                    </IconButton>
                                                </Tooltip>
                                                  : "" }    
                                            </Col>
                                        </Row>
                                    </Card>
                                </MyDiv>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col md={12}>
                                <Tabs defaultActiveKey={1} id="uncontrolled-tab-example" className="EmployeeTab">
                                    <Tab eventKey={1} title="Summary">
                                        <MyDiv className="TicketTabContainer">
                                            <Row>
                                                <Col md={12} className='GeneralTable mt-0'>
                                                    <Table className='bgGrey mt-0'>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="left">Department</TableCell>
                                                                <TableCell align="center"><LabelTag>Planned Start Date</LabelTag></TableCell>
                                                                <TableCell align="center"><LabelTag>Planned Handover Date</LabelTag></TableCell>
                                                                <TableCell align="center"><LabelTag>Actual Start Date</LabelTag></TableCell>
                                                                <TableCell align="center"><LabelTag>Actual HO Date</LabelTag></TableCell>
                                                                <TableCell align="left" width={280}><LabelTag>Resource</LabelTag></TableCell>
                                                                <TableCell align="center"><LabelTag>Estimated Hrs</LabelTag></TableCell>
                                                                <TableCell align="center"><LabelTag>Actual Hrs</LabelTag></TableCell>
                                                                <TableCell align="center"><LabelTag>Remaining / Exceeded Hrs</LabelTag></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {categories.map((category, index) => (
                                                                <CustomTableRow key={index} label={category} item={item} category={category} />
                                                            ))}
                                                            <TableRow className={item.pro_Overall_Deviated_Hours >= 0 ? "bg-success" : "bg-danger"}>
                                                              
                                                                <TableCell align="left" colSpan={4}>
                                                                    <LabelTag>Initial Estimation Hours : {item.pro_totalHours}</LabelTag>
                                                                </TableCell>
                                                                <TableCell align="right" colSpan={2}>
                                                                    <LabelTag>Total Deviations :</LabelTag>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <SpanTag>{item.pro_Overall_Estd_Hours}</SpanTag>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <SpanTag>{item.pro_Overall_Actual_Hours}</SpanTag>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <SpanTag>{item.pro_Overall_Deviated_Hours}</SpanTag>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </MyDiv>

                                    </Tab>
                                    <Tab eventKey={2} title="Development">
                                        <MyDiv className="TicketTabContainer">
                                            <Row>
                                                <Col md={6}>
                                                    <MyDiv className="ProfileCard">
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Estimation Hours</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.dev_pro_estimated_hours}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Planned Start Date and Time</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.dev_pro_planned_start_date ? moment(item.dev_pro_planned_start_date).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Planned Handover Date and Time</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.dev_pro_planned_end_date ? moment(item.dev_pro_planned_end_date).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Worked Developer</LabelTag></Col>
                                                            <Col xs={7}>
                                                                <SpanTag className="multipleName">
                                                                    {item.worked_dev.map((resources, index) => (
                                                                        <PTag key={index} className="mb-1">  {resources}</PTag>
                                                                    ))}
                                                                </SpanTag>

                                                            </Col>
                                                        </Row>
                                                    </MyDiv>
                                                </Col>
                                                <Col md={5}>
                                                    <MyDiv className="ProfileCard">
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Actual Hours</LabelTag></Col>
                                                            <Col xs={7}><SpanTag> {item.dev_pro_actual_hours} </SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Actual Start Date and Time</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.dev_pro_actual_start_date ? moment(item.dev_pro_actual_start_date).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Actual Handover Date and Time</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.dev_pro_actual_end_date ? moment(item.dev_pro_actual_end_date).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Notes</LabelTag></Col>
                                                            <Col xs={7}><SpanTag className="projectNotes">{item.dev_pro_notes}</SpanTag></Col>
                                                        </Row>
                                                    </MyDiv>
                                                </Col>
                                                <Col md={1} >
                                                    {module.ProjectManagement && (item.pro_owner === loggedEmpId || Rolename==="SuperAdmin") ?
                                                    <Tooltip title="Edit">                                                   
                                                        <IconButton aria-label="edit" color="default" onClick={(e) => handleEstimatDrawerToggle(
                                                            {
                                                                department: "Development",
                                                                estimation_hours: item.dev_pro_estimated_hours,
                                                                planned_start_date: item.dev_pro_planned_start_date ? moment(item.dev_pro_planned_start_date).format('YYYY-MM-DDTHH:mm') : null,
                                                                actual_start_date: item.dev_pro_actual_start_date ? moment(item.dev_pro_actual_start_date).format('YYYY-MM-DDTHH:mm') : null,
                                                                planned_end_date: item.dev_pro_planned_end_date ? moment(item.dev_pro_planned_end_date).format('YYYY-MM-DDTHH:mm') : null,
                                                                actual_end_date: item.dev_pro_actual_end_date ? moment(item.dev_pro_actual_end_date).format('YYYY-MM-DDTHH:mm') : null,
                                                                notes: item.dev_pro_notes
                                                            }
                                                        )}>
                                                            <Edit />
                                                        </IconButton>
                                                    </Tooltip>
                                                    : "" }
                                                </Col>
                                            </Row>
                                        </MyDiv>
                                    </Tab>
                                    <Tab eventKey={3} title="Quality Checking">
                                        <MyDiv className="TicketTabContainer">
                                            <Row>
                                                <Col md={6}>
                                                    <MyDiv className="ProfileCard">
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Estimation Hours</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.qc_pro_estimated_hours}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Planned Start Date and Time</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.qc_pro_planned_start_date ? moment(item.qc_pro_planned_start_date).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Planned Handover Date and Time</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.qc_pro_planned_end_date ? moment(item.qc_pro_planned_end_date).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Worked Developer</LabelTag></Col>
                                                            <Col xs={7}> <SpanTag className="multipleName">
                                                                {item.worked_qc.map((resources, index) => (
                                                                    <PTag key={index} className="mb-1">  {resources}</PTag>
                                                                ))}
                                                            </SpanTag>
                                                            </Col>
                                                        </Row>
                                                    </MyDiv>
                                                </Col>
                                                <Col md={5}>
                                                    <MyDiv className="ProfileCard">
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Actual Hours</LabelTag></Col>
                                                            <Col xs={7}><SpanTag> {item.qc_pro_actual_hours} </SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Actual Start Date and Time</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.qc_pro_actual_start_date ? moment(item.qc_pro_actual_start_date).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Actual Handover Date and Time</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.qc_pro_actual_end_date ? moment(item.qc_pro_actual_end_date).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Notes</LabelTag></Col>
                                                            <Col xs={7}><SpanTag className="projectNotes">{item.qc_pro_notes}</SpanTag></Col>
                                                        </Row>
                                                    </MyDiv>
                                                </Col>
                                                <Col md={1} >
                                                {module.ProjectManagement && (item.pro_owner === loggedEmpId || Rolename==="SuperAdmin") ?
                                                    <Tooltip title="Edit">
                                                        <IconButton aria-label="edit" color="default" onClick={(e) => handleEstimatDrawerToggle(
                                                            {
                                                                department: "QualityChecking",
                                                                estimation_hours: item.qc_pro_estimated_hours,
                                                                planned_start_date: item.qc_pro_planned_start_date ? moment(item.qc_pro_planned_start_date).format('YYYY-MM-DDTHH:mm') : null,
                                                                actual_start_date: item.qc_pro_actual_start_date ? moment(item.qc_pro_actual_start_date).format('YYYY-MM-DDTHH:mm') : null,
                                                                planned_end_date: item.qc_pro_planned_end_date ? moment(item.qc_pro_planned_end_date).format('YYYY-MM-DDTHH:mm') : null,
                                                                actual_end_date: item.qc_pro_actual_end_date ? moment(item.qc_pro_actual_end_date).format('YYYY-MM-DDTHH:mm') : null,
                                                                notes: item.qc_pro_notes
                                                            }
                                                        )}>
                                                            <Edit />
                                                        </IconButton>
                                                    </Tooltip>
                                                    : null }
                                                </Col>
                                            </Row>
                                        </MyDiv>
                                    </Tab>
                                    <Tab eventKey={4} title="Design">
                                        <MyDiv className="TicketTabContainer">
                                            <Row>
                                                <Col md={6}>
                                                    <MyDiv className="ProfileCard">
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Estimation Hours</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.design_pro_estimated_hours}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Planned Start Date and Time</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.design_pro_planned_start_date ? moment(item.design_pro_planned_start_date).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Planned Handover Date and Time</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.design_pro_planned_end_date ? moment(item.design_pro_planned_end_date).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Worked Developer</LabelTag></Col>
                                                            <Col xs={7}> <SpanTag className="multipleName">
                                                                {item.worked_design.map((resources, index) => (
                                                                    <PTag key={index} className="mb-1">  {resources}</PTag>
                                                                ))}
                                                            </SpanTag>
                                                            </Col>
                                                        </Row>
                                                    </MyDiv>
                                                </Col>
                                                <Col md={5}>
                                                    <MyDiv className="ProfileCard">
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Actual Hours</LabelTag></Col>
                                                            <Col xs={7}><SpanTag> {item.design_pro_actual_hours} </SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Actual Start Date and Time</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.design_pro_actual_start_date ? moment(item.design_pro_actual_start_date).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Actual Handover Date and Time</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.design_pro_actual_end_date ? moment(item.design_pro_actual_end_date).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Notes</LabelTag></Col>
                                                            <Col xs={7}><SpanTag className="projectNotes">{item.design_pro_notes}</SpanTag></Col>
                                                        </Row>
                                                    </MyDiv>
                                                </Col>
                                                <Col md={1} >
                                                {module.ProjectManagement && (item.pro_owner === loggedEmpId || Rolename==="SuperAdmin") ?
                                                    <Tooltip title="Edit">
                                                        <IconButton aria-label="edit" color="default" onClick={(e) => handleEstimatDrawerToggle(
                                                            {
                                                                department: "Design",
                                                                estimation_hours: item.design_pro_estimated_hours,
                                                                planned_start_date: item.design_pro_planned_start_date ? moment(item.design_pro_planned_start_date).format('YYYY-MM-DDTHH:mm') : null,
                                                                actual_start_date: item.design_pro_actual_start_date ? moment(item.design_pro_actual_start_date).format('YYYY-MM-DDTHH:mm') : null,
                                                                planned_end_date: item.design_pro_planned_end_date ? moment(item.design_pro_planned_end_date).format('YYYY-MM-DDTHH:mm') : null,
                                                                actual_end_date: item.design_pro_actual_end_date ? moment(item.design_pro_actual_end_date).format('YYYY-MM-DDTHH:mm') : null,
                                                                notes: item.design_pro_notes
                                                            }
                                                        )}>
                                                            <Edit />
                                                        </IconButton>
                                                    </Tooltip>
                                                    : null }
                                                </Col>
                                            </Row>
                                        </MyDiv>
                                    </Tab>
                                    <Tab eventKey={5} title="SEO">
                                        <MyDiv className="TicketTabContainer">
                                            <Row>
                                                <Col md={6}>
                                                    <MyDiv className="ProfileCard">
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Estimation Hours</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.seo_pro_estimated_hours}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Planned Start Date and Time</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.seo_pro_planned_start_date ? moment(item.seo_pro_planned_start_date).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Planned Handover Date and Time</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.seo_pro_planned_end_date ? moment(item.seo_pro_planned_end_date).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Worked Developer</LabelTag></Col>
                                                            <Col xs={7}> <SpanTag className="multipleName">
                                                                {item.worked_seo.map((resources, index) => (
                                                                    <PTag key={index} className="mb-1">  {resources}</PTag>
                                                                ))}
                                                            </SpanTag>
                                                            </Col>
                                                        </Row>
                                                    </MyDiv>
                                                </Col>
                                                <Col md={5}>
                                                    <MyDiv className="ProfileCard">
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Actual Hours</LabelTag></Col>
                                                            <Col xs={7}><SpanTag> {item.seo_pro_actual_hours} </SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Actual Start Date and Time</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.seo_pro_actual_start_date ? moment(item.seo_pro_actual_start_date).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Actual Handover Date and Time</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.seo_pro_actual_end_date ? moment(item.seo_pro_actual_end_date).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Notes</LabelTag></Col>
                                                            <Col xs={7}><SpanTag className="projectNotes">{item.seo_pro_notes}</SpanTag></Col>
                                                        </Row>
                                                    </MyDiv>
                                                </Col>
                                                <Col md={1} >
                                                {module.ProjectManagement && (item.pro_owner === loggedEmpId || Rolename==="SuperAdmin") ?
                                                    <Tooltip title="Edit">
                                                        <IconButton aria-label="edit" color="default" onClick={(e) => handleEstimatDrawerToggle(
                                                            {
                                                                department: "SEO",
                                                                estimation_hours: item.seo_pro_estimated_hours,
                                                                planned_start_date: item.seo_pro_planned_start_date ? moment(item.seo_pro_planned_start_date).format('YYYY-MM-DDTHH:mm') : null,
                                                                actual_start_date: item.seo_pro_actual_start_date ? moment(item.seo_pro_actual_start_date).format('YYYY-MM-DDTHH:mm') : null,
                                                                planned_end_date: item.seo_pro_planned_end_date ? moment(item.seo_pro_planned_end_date).format('YYYY-MM-DDTHH:mm') : null,
                                                                actual_end_date: item.seo_pro_actual_end_date ? moment(item.seo_pro_actual_end_date).format('YYYY-MM-DDTHH:mm') : null,
                                                                notes: item.seo_pro_notes
                                                            }
                                                        )}>
                                                            <Edit />
                                                        </IconButton>
                                                    </Tooltip>
                                                    : null}
                                                </Col>
                                            </Row>
                                        </MyDiv>
                                    </Tab>
                                    <Tab eventKey={6} title="Project Management">
                                        <MyDiv className="TicketTabContainer">
                                            <Row>
                                                <Col md={6}>
                                                    <MyDiv className="ProfileCard">
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Estimation Hours</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.pm_pro_estimated_hours}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Planned Start Date and Time</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.pm_pro_planned_start_date ? moment(item.pm_pro_planned_start_date).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Planned Handover Date and Time</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.pm_pro_planned_end_date ? moment(item.pm_pro_planned_end_date).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Worked Developer</LabelTag></Col>
                                                            <Col xs={7}> <SpanTag className="multipleName">
                                                                {item.worked_pm.map((resources, index) => (
                                                                    <PTag key={index} className="mb-1">  {resources}</PTag>
                                                                ))}
                                                            </SpanTag>
                                                            </Col>
                                                        </Row>
                                                    </MyDiv>
                                                </Col>
                                                <Col md={5}>
                                                    <MyDiv className="ProfileCard">
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Actual Hours</LabelTag></Col>
                                                            <Col xs={7}><SpanTag> {item.pm_pro_actual_hours} </SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Actual Start Date and Time</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.pm_pro_actual_start_date ? moment(item.pm_pro_actual_start_date).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Actual Handover Date and Time</LabelTag></Col>
                                                            <Col xs={7}><SpanTag>{item.pm_pro_actual_end_date ? moment(item.pm_pro_actual_end_date).format('MMM-DD-YYYY hh:mm a') : null}</SpanTag></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={5}><LabelTag>Notes</LabelTag></Col>
                                                            {/* <Col xs={7}><SpanTag className="projectNotes">{item.pm_pro_notes}</SpanTag></Col> */}
                                                        </Row>
                                                    </MyDiv>
                                                </Col>
                                                <Col md={1} >
                                                {module.ProjectManagement && (item.pro_owner === loggedEmpId || Rolename==="SuperAdmin") ?
                                                    <Tooltip title="Edit">
                                                        <IconButton aria-label="edit" color="default" onClick={(e) => handleEstimatDrawerToggle(
                                                            {
                                                                department: "ProjectManagement",
                                                                estimation_hours: item.pm_pro_estimated_hours,
                                                                planned_start_date: item.pm_pro_planned_start_date ? moment(item.pm_pro_planned_start_date).format('YYYY-MM-DDTHH:mm') : null,
                                                                actual_start_date: item.pm_pro_actual_start_date ? moment(item.pm_pro_actual_start_date).format('YYYY-MM-DDTHH:mm') : null,
                                                                planned_end_date: item.pm_pro_planned_end_date ? moment(item.pm_pro_planned_end_date).format('YYYY-MM-DDTHH:mm') : null,
                                                                actual_end_date: item.pm_pro_actual_end_date ? moment(item.pm_pro_actual_end_date).format('YYYY-MM-DDTHH:mm') : null,
                                                                // notes: item.pm_pro_notes
                                                            }
                                                        )}>
                                                            <Edit />
                                                        </IconButton>
                                                    </Tooltip>
                                                    : null }
                                                </Col>
                                            </Row>
                                        </MyDiv>
                                    </Tab>
                                    <Tab eventKey={7} title="TimeSheet">
                                        <Row className='mt-0'>
                                            {/* Project Milestones start */}
                                            <Col md={12}>
                                                <ProjectTasks CoreProjectId={item._id} />
                                            </Col>
                                            {/* Project Milestones End */}
                                        </Row >
                                    </Tab>
                                    {(item.pro_owner === loggedEmpId || Rolename==="SuperAdmin") ? (
                                         <Tab eventKey={8} title="Notes">
                                         <Row className='mt-0'>
                                             {/* Project Milestones start */}
                                             <Col md={12}>
                                             <ProjectNotes projectId={item._id}/>
                                             </Col>
                                             {/* Project Milestones End */}
                                         </Row >
                                     </Tab>
                                    ) : null}
                                   
                                </Tabs>
                            </Col>
                        </Row>
                        <Drawer anchor="right" open={projectDrawerState} onClose={() => handleProjectsDrawerToggle(false)} >
                            <FormProjects handleClose={updateDrawer} projectEditId={data} props />
                        </Drawer>
                        <Drawer anchor="right" open={estimatDrawerState} onClose={() => handleEstimatDrawerToggle(false)} >
                            <EstimateForm handleClose={updateEstimatDrawer} projectID={id} props editMode={true} deptData={deptEstData} />
                        </Drawer>
                    </React.Fragment >
                )) : null || <NoDataFound />
            }
        </>
        }
        </>
    );
}
export default NewDetailProject;
