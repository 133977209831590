import React, { useEffect } from 'react';
import { Sidebar, Menu, MenuItem, useProSidebar, SubMenu } from 'react-pro-sidebar';
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import Header from "./Common/header";
import { MyDiv, PTag } from "./Common/Components";
import { AiFillDashboard, AiOutlineUser, AiFillSetting, AiOutlineFundProjectionScreen, AiFillMinusCircle } from "react-icons/ai";
import { HiUserGroup } from "react-icons/hi";
import { BsCashCoin, BsPersonLinesFill  } from "react-icons/bs";
import { GiNotebook, GiChessKing, GiHistogram } from "react-icons/gi";
import { MdHolidayVillage, MdWifiProtectedSetup, MdHomeWork } from "react-icons/md";
import { VscFileSubmodule } from "react-icons/vsc";
import { TbAsset, TbStack3 } from "react-icons/tb";
import { FiUserCheck } from "react-icons/fi";
import { FaSchool, FaTasks, FaProjectDiagram, FaRegCalendarTimes, FaDoorOpen } from "react-icons/fa";
import { BiSitemap, BiMenu, BiCalendar } from "react-icons/bi";
import { TbReceiptTax } from "react-icons/tb";
import { Button } from "react-bootstrap";
import { TbFolderX } from "react-icons/tb";


function TemplateCore() {
    const [module, setModule] = React.useState([{
        Masters: '',
    }
    ])

    const navigate = useNavigate();
    const { collapseSidebar } = useProSidebar();
    const pathname = useLocation().pathname;

    useEffect(() => {
        async function init() {
            const data = await localStorage.getItem('role');

            setModule(JSON.parse(data));
        }
        init();
        if ((localStorage.getItem("token") === null) && (localStorage.getItem(" ") === null)) {
            navigate("/login");
        }
    }, [navigate]);


    return (
        <div>
            <Header />
            <MyDiv className="GeneralContainer">
                <MyDiv className="LeftSideContainer">
                    <Button className="Sidebarcollapse" onClick={() => collapseSidebar()}>
                        <BiMenu />
                    </Button>
                    <Sidebar>
                        <Menu>
                            <MenuItem Tooltip title="Dashboard" className={`${pathname === '/dashboard' ? "menuActive" : ""}`} icon={<AiFillDashboard />} component={<Link to="/dashboard" />}>Dashboard</MenuItem>
                            {module.EmployeeModules && module.EmployeeModules.view === "1" ?
                                <MenuItem Tooltip title="Manage Employee" className={`${pathname === '/employee' ? "menuActive" : ""}`} icon={<HiUserGroup />} component={<Link to="/employee" />}>Manage Employee</MenuItem>
                                : ""}
                            {/* <MenuItem Tooltip title="Resignations" className={`${pathname === '/employee/resignation' ? "menuActive" : ""}`} icon={<HiUserGroup />} component={<Link to="/employee/resignation" />}>Resignations</MenuItem> */}
                            {module.SalaryModule && module.SalaryModule.view === "1" ?
                                <SubMenu Tooltip title="Finance Management" label="Finance Management " className={`${pathname === '/finance' ? "menuActive" : ""}`} icon={<BsCashCoin />}>
                                    <MenuItem Tooltip title="Manage Salary" className={`${pathname === '/finance/salary' ? "menuActive" : ""}`} icon={<BsCashCoin />} component={<Link to="/finance/salary" />}>Employee Salary </MenuItem>
                                    <MenuItem Tooltip title="Tax Settings" className={`${pathname === '/finance/tax-settings' ? "menuActive" : ""}`} icon={<TbReceiptTax />} component={<Link to="/finance/tax-settings" />}>Tax Settings </MenuItem>
                                    <MenuItem Tooltip title="LOP Management" className={`${pathname === '/finance/lop-management' ? "menuActive" : ""}`} icon={<AiFillMinusCircle />} component={<Link to="/finance/lop-management" />}>LOP Management </MenuItem>
                                </SubMenu>
                                : ''}
                            <MenuItem Tooltip title="Timesheet" className={`${pathname === '/new-projects/my-task' ? "menuActive" : ""}`} icon={<FaTasks />} component={<Link to="/new-projects/my-task" />}>Timesheet</MenuItem>
                            <SubMenu Tooltip title="Project Management" label="Project Management" icon={<AiOutlineFundProjectionScreen />}>
                                {module.CustomerModule && module.CustomerModule.view === "1" ?
                                    <MenuItem Tooltip title="Manage Customer" className={`${pathname === '/customer' ? "menuActive" : ""}`} icon={<BsPersonLinesFill  />} component={<Link to="/customer" />}>Manage Customer</MenuItem>
                                    : ''}
                                {module.ProjectManagement && module.ProjectManagement.view === "1" ?
                                    <MenuItem Tooltip title="Manage Projects" className={`${pathname === '/new-projects' ? "menuActive" : ""}`} icon={<TbStack3 />} component={<Link to="/new-projects" />}>Manage Projects</MenuItem>
                                    : ''}
                                {/* {module.ProjectManagement && module.ProjectManagement.view === "1" ?
                                    <MenuItem Tooltip title="Existing Projects" className={`hide ${pathname === '/projects' ? "menuActive" : ""}`} icon={<TbStack3 />} component={<Link to="/projects" />}>Existing Projects</MenuItem>
                                    : ''} */}
                                {module.ProjectManagement && module.ProjectManagement.view === "1" ?  <MenuItem Tooltip title="for reference only" className={`${pathname === '/projects' ? "menuActive" : ""}`} icon={<TbFolderX />} component={<Link to="/projects" />}>Old Projects</MenuItem> : ''}
                                   
                                   
                                {/* <MenuItem Tooltip title="Resource Assignation" className={`${pathname === '/projects/resource-assignation' ? "menuActive" : ""}`} icon={<FaUsers />} component={<Link to="/projects/resource-assignation" />}>Resource Assignation</MenuItem> */}
                            </SubMenu>
                            {module.TicketManagement && module.TicketManagement.view === "1" ?
                                <SubMenu Tooltip title="Ticket Management" label="Ticket Management" icon={<AiOutlineFundProjectionScreen />}>
                                    {module.TicketManagement && module.TicketManagement.add === "1" ?
                                        <MenuItem Tooltip title="Manage Ticket" className={`${pathname === '/tickets' ? "menuActive" : ""}`} icon={<BsCashCoin />} component={<Link to="/tickets" />}>Manage Tickets </MenuItem>
                                        : ''}
                                    {/* <MenuItem Tooltip title="TimeSheet" className={`${pathname === '/tickets/time-sheet' ? "menuActive" : ""}`} icon={<BsCashCoin />} component={<Link to="/tickets/time-sheet" />}>Daily Time Sheet </MenuItem> */}
                                    {module.TicketManagement && module.TicketManagement.add === "1" ?
                                        <MenuItem Tooltip title="Task Planner" className={`${pathname === '/tickets/task-planner' ? "menuActive" : ""}`} icon={<BsCashCoin />} component={<Link to="/tickets/task-planner" />}>Task Planner </MenuItem>
                                        : ''}
                                    {module.TicketManagement && module.TicketManagement.add === "1" ?
                                        <MenuItem Tooltip title="Ticket-Wise Report" className={`${pathname === '/tickets/ticket-wise-report' ? "menuActive" : ""}`} icon={<BsCashCoin />} component={<Link to="/tickets/ticket-wise-report" />}>Ticket-Wise Report</MenuItem>
                                        : ''}
                                    {module.TicketManagement && module.TicketManagement.add === "1" ?
                                        <MenuItem Tooltip title="Manage SOW Report" className={`${pathname === '/tickets/sow-report' ? "menuActive" : ""}`} icon={<BsCashCoin />} component={<Link to="/tickets/sow-report" />}>Manage SOW Report</MenuItem>
                                        : ''}
                                </SubMenu>
                                : ''}
                            {(module.ProjectReporting && module.ProjectReporting.view === "1") || (module.LeaveReporting && module.LeaveReporting.view === "1") ?
                                <SubMenu Tooltip title="Reports" label="Reports" icon={<GiHistogram />}>
                                    {/* {module.ProjectReporting && module.ProjectReporting.view === "1" ?
                                        <MenuItem Tooltip title="Project Reports" icon={<FaProjectDiagram />} className={`${pathname === '/reports/project-reports' ? "menuActive" : ""}`} component={<Link to="/reports/project-reports" />}>Project Report</MenuItem>
                                        : ''} */}
                                    {module.LeaveReporting && module.LeaveReporting.view === "1" ?
                                        <MenuItem Tooltip title="Leave Reports" icon={<FaDoorOpen />} className={`${pathname === '/reports/leave-reports' ? "menuActive" : ""}`} component={<Link to="/reports/leave-reports" />}>Leave Report</MenuItem>
                                        : ''}
                                    {module.LeaveReporting && module.LeaveReporting.view === "1" ?
                                        <MenuItem Tooltip title="Work from Home Reports" icon={<MdHomeWork />} className={`${pathname === '/reports/wfh-reports' ? "menuActive" : ""}`} component={<Link to="/reports/wfh-reports" />}>WFH Report</MenuItem>
                                        : ''}
                                    {/* {module.ProjectReporting && module.ProjectReporting.view === "1" ?
                                        <MenuItem Tooltip title="Time Sheet Defaulters" icon={<FaRegCalendarTimes />} className={`${pathname === '/reports/timesheet-defaulters' ? "menuActive" : ""}`} component={<Link to="/reports/timesheet-defaulters" />}>TimeSheet Defaulters</MenuItem>
                                        : ''}
                                    {module.ProjectReporting && module.ProjectReporting.view === "1" ?
                                        <MenuItem Tooltip title="Approval Defaulters" icon={<FiUserCheck />} className={`${pathname === '/reports/approval-defaulters' ? "menuActive" : ""}`} component={<Link to="/reports/approval-defaulters" />}>Approval Defaulters</MenuItem>
                                        : ''} */}
                                    {module.ProjectReporting && module.ProjectReporting.view === "1" ?
                                        <MenuItem Tooltip title="Employee Work Report" icon={<FiUserCheck />} className={`${pathname === '/reports/employee-work-report' ? "menuActive" : ""}`} component={<Link to="/reports/employee-work-report" />}>Employee Work Report</MenuItem>
                                        : ''}
                                </SubMenu>
                                : ''}
                            {module.Inventory && module.Inventory.view === "1" ?
                                <SubMenu Tooltip title="Manage Inventory" label="Manage Inventory" className={`${pathname.startsWith('/inventory') ||
                                    pathname.startsWith('/inventory/monitor') || pathname.startsWith('/inventory/cpu') || pathname.startsWith('/inventory/keyboard') || pathname.startsWith('/inventory/mouse') || pathname.startsWith('/inventory/headphone') || pathname.startsWith('/inventory/dongle') || pathname.startsWith('/inventory/miscellaneous') ? "menuActive" : ""}`} icon={<GiNotebook />}>
                                    <MenuItem Tooltip title="Dashboard" className={`${pathname === '/inventory' ? "menuActive" : ""}`} component={<Link to="/inventory" />}>Dashboard</MenuItem>
                                    <MenuItem Tooltip title="Monitor" className={`${pathname === '/inventory/monitor' ? "menuActive" : ""}`} component={<Link to="/inventory/monitor" />}>Monitor</MenuItem>
                                    <MenuItem Tooltip title="CPU" className={`${pathname === '/inventory/cpu' ? "menuActive" : ""}`} component={<Link to="/inventory/cpu" />}>CPU</MenuItem>
                                    <MenuItem Tooltip title="KeyBoard" className={`${pathname === '/inventory/keyboard' ? "menuActive" : ""}`} component={<Link to="/inventory/keyboard" />}>KeyBoard</MenuItem>
                                    <MenuItem Tooltip title="Mouse" className={`${pathname === '/inventory/mouse' ? "menuActive" : ""}`} component={<Link to="/inventory/mouse" />}>Mouse</MenuItem>
                                    <MenuItem Tooltip title="Headphone" className={`${pathname === '/inventory/headphone' ? "menuActive" : ""}`} component={<Link to="/inventory/headphone" />}>Headphone</MenuItem>
                                    <MenuItem Tooltip title="dongle" className={`${pathname === '/inventory/dongle' ? "menuActive" : ""}`} component={<Link to="/inventory/dongle" />}>Dongle</MenuItem>
                                    <MenuItem Tooltip title="Laptop" className={`${pathname === '/inventory/laptop' ? "menuActive" : ""}`} component={<Link to="/inventory/laptop" />}>Laptop</MenuItem>
                                    <MenuItem Tooltip title="Miscellaneous" className={`${pathname === '/inventory/miscellaneous' ? "menuActive" : ""}`} component={<Link to="/inventory/miscellaneous" />}>Miscellaneous</MenuItem>
                                </SubMenu> : ''}
                            {/* <MenuItem Tooltip title="Manage Inventory" className={`${pathname === '/inventory' ? "menuActive" : ""}`} icon={<TbAsset />} component={<Link to="/inventory" />}>Manage Inventory </MenuItem> */}
                            {module.Masters && module.Masters.view === "1" ?
                                <SubMenu Tooltip title="Masters" label="Masters" className={`${pathname === '/master' ? "menuActive" : ""}`} icon={<GiNotebook />}>
                                    <MenuItem Tooltip title="Holiday" className={`${pathname === '/master/holiday' ? "menuActive" : ""}`} icon={<MdHolidayVillage />} component={<Link to="/master/holiday" />}>Holiday</MenuItem>
                                    <MenuItem Tooltip title="Roles" className={`${pathname === '/master/roles' ? "menuActive" : ""}`} icon={<AiOutlineUser />} component={<Link to="/master/roles" />}>Roles</MenuItem>
                                    <MenuItem Tooltip title="Modules" className={`${pathname === '/master/modules' ? "menuActive" : ""}`} icon={<VscFileSubmodule />} component={<Link to="/master/modules" />}>Modules</MenuItem>
                                    <MenuItem Tooltip title="Department" className={`${pathname === '/master/department' ? "menuActive" : ""}`} icon={<FaSchool />} component={<Link to="/master/department" />}>Department</MenuItem>
                                </SubMenu> : ''}
                            {module.Settings && module.Settings.view === "1" ?
                                <SubMenu Tooltip title="Settings" label="Settings" className={`${pathname === '/settings' ? "menuActive" : ""}`} icon={<AiFillSetting />}>
                                    <MenuItem Tooltip title="General Settings" className={`${pathname === '/settings/generalsettings' ? "menuActive" : ""}`} icon={<MdWifiProtectedSetup />} component={<Link to="/settings/generalsettings" />}>General Settings</MenuItem>
                                    <MenuItem Tooltip title="Site Settings" className={`${pathname === '/settings/sitesettings' ? "menuActive" : ""}`} icon={<BiSitemap />} component={<Link to="/settings/sitesettings" />}>Site Settings</MenuItem>
                                    <MenuItem Tooltip title="Documents" className={`${pathname === '/settings/documents' ? "menuActive" : ""}`} icon={<BiSitemap />} component={<Link to="/settings/documents" />}>Documents</MenuItem>
                                    <MenuItem Tooltip title="ApiKey" className={`${pathname === '/settings/apikey' ? "menuActive" : ""}`} icon={<BiSitemap />} component={<Link to="/settings/apikey" />}>API Key</MenuItem>
                                    <MenuItem Tooltip title="Visitor management" className={`${pathname === '/settings/visitors' ? "menuActive" : ""}`} icon={<BiSitemap />} component={<Link to="/settings/visitors" />}>Visitor management</MenuItem>
                                </SubMenu>
                                : ''}
                            {module.LeaveModule && module.LeaveModule.view === "1" ?
                                <MenuItem Tooltip title="Manage Leave" className={`${pathname === '/leave' ? "menuActive" : ""}`} icon={<BiCalendar />} component={<Link to="/leave" />}>Manage Leave</MenuItem>
                                : ''}
                        </Menu>
                    </Sidebar>
                </MyDiv>
                <MyDiv className="RightMainContainer">
                    <MyDiv className="Right-Side-Main-Container">
                        <Outlet />
                    </MyDiv>
                    <MyDiv className="footer-copyrights ">
                        <PTag className="text-center mt-15">{(new Date().getFullYear())} @ SNS. All rights reserved</PTag>
                    </MyDiv>
                </MyDiv>
            </MyDiv>
        </div>
    );
}

export default TemplateCore;