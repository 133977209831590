export const MartialStatus = [
    { value: "single", label: "Single" },
    { value: "married", label: "Married" }
  ];
  export const Gender = [
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
    ];
  export const employeeMode = [
      { value: "permanent", label: "Permanent" },
      { value: "contract", label: "Contract" },
      { value: "Work Form Home", label: "Work Form Home" },
    ];
    export const employeeStatus = [
      { value: "active", label: "Active" },
      { value: "inactive", label: "In Active" },
    ];
    export const employeeRelation = [
      { value: "father", label: "Father" },
      { value: "mother", label: "Mother" },
      { value: "husband", label: "Husband" },
      { value: "wife", label: "Wife" },
      { value: "guardian", label: "Guardian" },
      { value: "brother", label: "Brother" },
      { value: "sister", label: "Sister" },
      { value: "uncle", label: "Uncle" },
      { value: "aunty", label: "Aunty" },
    ];

    export const employeeSkill = [
      { value: "html", label: "Html" },
      { value: "php", label: "PHP" },
      { value: "css", label: "CSS3" },
      { value: "Data-analysis", label: "Data analysis" },
      { value: "Web-development", label: "Web development" },
      { value: "UX-design", label: "UX design" },
      { value: "Angular", label: "Angular" },
      { value: "React", label: "React" },
      { value: "Java", label: "Java" },
    ];
    export const employeeEducationType = [
      { value: "ug", label: "UG" },
      { value: "pg", label: "PG" },
      { value: "school", label: "School" },
      { value: "institute", label: "Institute" }
    ];
    export const DeviceStatusList = [
      { value: "InStock", label: "InStock" },
      { value: "Assigned", label: "Assigned" },
      { value: "Defect", label: "Defect" },
      { value: "Scrap", label: "Scrap" },
      { value: "OutForService", label: "OutForService" }
    ];
    export const DeviceWarrantyStatus = [
      { value: "Claimed", label: "Claimed" },
      { value: "Expired", label: "Expired" },
      { value: "Warranty Applied", label: "Warranty Applied" },
      { value: "institute", label: "Institute" }
    ];
    export const Department = [
      { value: "GR Team", label: "GR Team" },
      { value: "Redmed Team", label: "Redmed Team" },
      { value: "Internal Team", label: "Internal Team" },
      { value: "QC Team", label: "QC Team" }
    ];
    export const Designation = [
      { value: "Developer", label: "Developer" },
      { value: "Team Lead", label: "Team Lead" },
      { value: "Project Lead", label: "Project Lead" },
      { value: "QC", label: "QC" }
    ];
    export const WorkMode = [
      { value: "Work From Home", label: "Work From Home" },
      { value: "Work From Office", label: "Work From Office" },
    ];
    export const ModuleStatus = [
      { value: "Active", label: "Active" },
      { value: "Inactive", label: "InActive" },
    ];
    export const LeaveType = [
      { value: "Casual", label: "Casual" },
      { value: "Medical", label: "Medical" },
      { value: "Permission", label: "Permission" },
    ];
    export const reportLeaveMode = [
      { value: "1", label: "FullDay" },
      { value: "3", label: "Half Day" },
      { value: "4", label: "Permissions" },
    ];
    export const LeaveMode = [
      { value: "1", label: "FullDay" },
      { value: "3", label: "Half Day" },
      // { value: "4", label: "Permissions" },
    ];
    export const ThemeStyle = [
      { value: "Theme1", label: "Theme1" },
      { value: "Theme2", label: "Theme2" },
      { value: "Theme3", label: "Theme3" },
      { value: "Theme4", label: "Theme4" },
      { value: "Theme5", label: "Theme5" },
    ];
    export const TaxRegime = [
      { value: "Old", label: "Old" },
      { value: "New", label: "New" },
    ];
    export const customerStatus = [
      { value: "Active", label: "Active" },
      { value: "Inactive", label: "InActive" },
    ];
    export const projectStatus = [
      { value: "Inprogress", label: "Inprogress" },
      { value: "Completed", label: "Completed" },
      { value: "Hold", label: "Hold" },
      { value: "Cancelled", label: "cancelled" },
      { value: "Not Yet Started", label: "Not yet started" },
      { value: "Canceled", label: "Cancelled" },
    ];
    export const projectType = [
      { value: "Fix Bid", label: "Fix Bid" },
      { value: "Progressive", label: "Progressive" },
      { value: "Long Term", label: "Long Term" },
      { value: "Time and material", label: "Time and material" },
      { value: "Flexible", label: "Flexible" }
    ];
    export const projectContractStatus = [
      { value: "Active", label: "Active" },
      { value: "Expired", label: "Expired" }
    ];
    export const employeeLoanStatus = [
      { value: "Active", label: "Active" },
      { value: "Closed", label: "Closed" }
    ];
    export const employeCode = [
      { value: "Code-C", label: "Code-C" },
      { value: "Code-B", label: "Code-B" },
      { value: "Code-T", label: "Code-T" },
      { value: "Code-OH", label: "Code-OH" },
    ];
    export const BGVType = [
      { value: "Internal", label: "Internal" },
      { value: "External", label: "External" }
    ];
    export const BGVStatus = [
      { value: "Yet To Start", label: "Yet To Start" },
      { value: "In Progress", label: "In Progress" },
      { value: "Completed", label: "Completed" },
      { value: "Failed", label: "Failed" },
      { value: "Cancelled", label: "Cancelled" },
    ];
    export const TaskStatus = [
      { value: "Yet To Start", label: "Yet To Start" },
      { value: "In Progress", label: "In Progress" },
      { value: "Completed", label: "Completed" },
      { value: "Hold", label: "Hold" },
      { value: "Cancelled", label: "Cancelled" },
    ];
    export const TaskStatusGR = [
      { label: "Yet to Start", value: "Yet to Start" },
      { label: "To Est", value: "To Est" },
      { label: "Est & Shared", value: "Est & Shared" },
      { label: "Analysis", value: "Analysis" },
      { label: "Ready for Dev", value: "Ready for Dev" },
      { label: "In Dev", value: "In Dev" },
      { label: "In Merch", value: "In Merch" },
      { label: "Dev Done", value: "Dev Done" },
      { label: "Merch Done", value: "Merch Done" },
      { label: "In UT", value: "In UT" },
      { label: "Ready for Test", value: "Ready for Test" },
      { label: "In Test", value: "In Test" },
      { label: "In QA", value: "In QA" },
      { label: "QA Passed", value: "QA Passed" },
      { label: "Ready for UAT", value: "Ready for UAT" },
      { label: "In UAT", value: "In UAT" },
      { label: "Ready for QA/STG", value: "Ready for QA/STG" },
      { label: "Ready for Prod", value: "Ready for Prod" },
      { label: "UAT Feedback", value: "UAT Feedback" },
      { label: "Defect Fixing", value: "Defect Fixing" },
      { label: "Defect Mgmt", value: "Defect Mgmt" },
      { label: "Clarification Raised", value: "Clarification Raised" },
      { label: "Hold", value: "Hold" },
      { label: "Live", value: "Live" }
    ];
    
    export const TaskPriority = [
      { value: "High", label: "High" },
      { value: "Medium", label: "Medium" },
      { value: "Low", label: "Low" },
    ];
    export const MilestoneStatus = [
      { value: "Inprogress", label: "Inprogress" },
      { value: "Completed", label: "Completed" },
      { value: "Hold", label: "Hold" },
      { value: "Cancelled", label: "cancelled" },
      { value: "Not Yet Started", label: "Not yet started" },
      { value: "Canceled", label: "Cancelled" },
    ];
    export const MilestonePriority = [
      { value: "High", label: "High" },
      { value: "Medium", label: "Medium" },
      { value: "Low", label: "Low" },
    ];
    export const projectBillingType = [
      { value: "Billable", label: "Billable" },
      { value: "UnBillable", label: "UnBillable" },
      { value: "Bench", label: "Bench" },
      { value: "Training", label: "Training" },
    ];
    export const leaveStatus = [
      {value : "0", label : "Not Approved"},
      {value : "1", label : "Approved"},
      {value : "2", label : "Cancelled"}
    ];
    export const APICategoryList = [
      {value : "Employee", label : "Employee"}
    ];
    export const TicketBrand = [
      {value : "KindScience-DW", label : "KindScience DW"},
      {value : "Crepeerase-DW", label : "Crepeerase DW"},
      {value : "SmileActives-DW", label : "SmileActives DW"},
      {value : "KindScience-Shopify", label : "KindScience Shopify"},
      {value : "Crepeerase-Shopify", label : "Crepeerase Shopify"},
      {value : "SmileActives-Shopify", label : "SmileActives Shopify"},
    ];
    export const TicketStatus = [
      {value : "Created", label : "Created"},
      {value : "Incomplete-Requirement", label : "Incomplete Requirement"},
      {value : "Estimation-Inprogress", label : "Estimation Inprogress"},
      {value : "Merchandising-Inprogress", label : "Merchandising Inprogress"},
      {value : "Development-Inprogress", label : "Development Inprogress"},
      {value : "QC-Inprogress", label : "QC Inprogress"},
      {value : "Defect-Management", label : "Defect Management"},
      {value : "UAT", label : "UAT"},
      {value : "HOLD", label : "HOLD"},
      {value : "Completed", label : "Completed"}
    ];
    export const TicketPriority = [
      {value : "High", label : "High"},
      {value : "Medium", label : "Medium"},
      {value : "Low", label : "LOW"},
      {value : "Fast-Line", label : "Fast Line"}
    ];
    export const TicketHandoverTeam = [
      {value : "Dev", label : "Dev"},
      {value : "Merchandising", label : "Merchandising"},
      {value : "QC", label : "QC"},
      {value : "UAT", label : "UAT"}
    ];
    export const GRDevelopmentTeam = [
      {value : "Merchandising", label : "Merchandising"},
      {value : "Development", label : "Development"},
      {value : "QualityChecking", label : "QualityChecking"},
      {value : "Automation", label : "Automation"}
    ];
    export const DevelopmentTeam = [
      {value : "Development", label : "Development"},
      {value : "QualityChecking", label : "Quality Checking"},
      {value : "Design", label : "Designing"},
      {value : "SEO", label : "SEO"},
      {value : "ProjectManagement", label : "Project Management"}
    ];
    export const ApproverDepartments = [
      {value : "Development", label : "Development"},
      {value : "QualityChecking", label : "Quality Checking"},
      {value : "Design", label : "Design"},
      {value : "SEO", label : "SEO"},
      {value : "ProjectManagement", label : "Project Management"},
    ]

    export const Month = [
      { label: 'January', value: 1 },
      { label: 'February', value: 2 },
      { label: 'March', value: 3 },
      { label: 'April', value: 4 },
      { label: 'May', value: 5 },
      { label: 'June', value: 6 },
      { label: 'July', value: 7 },
      { label: 'August', value: 8 },
      { label: 'September', value: 9 },
      { label: 'October', value: 10 },
      { label: 'November', value: 11 },
      { label: 'December', value: 12 }
  ];
  

    export const dateFormat = "MMM DD, YYYY"


    export const dateFormatTwo = "YYYY-MM-DDTHH:mm:ss.SSS+00:00"

