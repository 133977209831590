import React from 'react';
import { Col, Row} from "react-bootstrap";
import { HeadingFour, HeadingThree, PTag, MyDiv } from "../Common/Components";
import { Link, useNavigate, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { api } from "../Common/Constants/apiurl";
import axios from 'axios';
import swal from "sweetalert2";
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const API_BASE_URL = api.API_BASE_URL;

function CoreUpdatePassword() {
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    emp_ref_id: Yup.string(),
    newpassword: Yup.string()
      .required('Confirm Password is required')
      .min(6, 'Password must be at least 6 characters'),
    confrimPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('newpassword')], 'Passwords must match')

  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (data, e) => {
    e.preventDefault();
    var url = API_BASE_URL + 'updatepassword';
    var method = 'post';
    delete data.confrimPassword;
    axios({ method, url, data })
      .then(res => {
        if (res.status === 201) {
          swal.fire({
            text: "Successfully Updated, Please login again",
            icon: "success",
            type: "success"
          });
          //CoreChangepassword();            
          navigate("/");
        }

      })
      .catch(res => {
        swal.fire({
          text: res.response.data,
          icon: "error",
          type: "error"
        });
      }); 
    reset();
    return false;
  }
  const settings = {
    dots: true,
    infinite: false,
    navs: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  let { id } = useParams();
  return (
    <MyDiv>
      <Row className="row GeneralHeading login-form">
        <MyDiv className="left">
          <Col md={6}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <img src={require('../../assets/images/sns-logo.png')} alt="logo" />
              <HeadingFour className="card-title  login-head">
                Internal HRMS Application<br /><br />
                Update password
              </HeadingFour>
              <Row className="mb-4">
                <Form.Control type='hidden' name="securityToken" value={id} className={`form-control ${errors.securityToken ? 'is-invalid' : ''}`} required {...register('securityToken')} />
                <Col md={12}>
                  <Form.Control type="password" margin="dense" placeholder="New Password" label="New Password" variant="standard" name="newpassword" required {...register('newpassword')} className={`form-control ${errors.newpassword ? 'is-invalid' : ''}`} />
                  <div className="invalid-feedback">{errors.newpassword?.message}</div>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md={12}>
                  <Form.Control type="password" margin="dense" placeholder="Confirm Password" label="Confirm Password" variant="standard" name="confrimPassword" required {...register('confrimPassword')} className={`form-control ${errors.confrimPassword ? 'is-invalid' : ''}`} />
                  <div className="invalid-feedback">{errors.confrimPassword?.message}</div>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md={12} >
                  <MyDiv>
                    <button type="submit" className="btn primary-btn add-cta mt-2 mb-3 text-center float-left">Update Password</button> &nbsp;
                    <button type="button" onClick={() => reset()} className="btn secondry-btn  add-cta mt-2 mb-3 ml-1 text-center">Reset</button>
                  </MyDiv>
                </Col>
              </Row>
              <Col md={12} className="text-end">
                <Link to="/login" className="mt-15  d-block">Back to Login</Link>
              </Col>
            </form>

          </Col>
          <Col md={6} className="right-slide">
            <Slider {...settings}>
              <MyDiv className="slide">
                <img src={require('../../assets/images/employee.png')} alt="icon" />
                <HeadingThree className="mt-3 mb-3">Dashboard</HeadingThree>
                <PTag>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure, odio! consectetur adipisicing elit. Iure, odio!</PTag>
              </MyDiv>
              <MyDiv className="slide">
                <img src={require('../../assets/images/icon.png')} alt="icon" />
                <HeadingThree className="mt-3 mb-3">Employee</HeadingThree>
                <PTag>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure, odio! consectetur adipisicing elit. Iure, odio!</PTag>
              </MyDiv>
              <MyDiv className="slide">
                <img src={require('../../assets/images/employee.png')} alt="icon" />
                <HeadingThree className="mt-3 mb-3">Salary</HeadingThree>
                <PTag>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure, odio! consectetur adipisicing elit. Iure, odio!</PTag>
              </MyDiv>
            </Slider>
          </Col>
        </MyDiv>
      </Row>
    </MyDiv>
  );
}

export default CoreUpdatePassword;