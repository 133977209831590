// export const api = {
//     //API_BASE_URL : 'http://192.168.0.26:8084/', 
//     //API_BASE_URL : 'http://192.168.0.20:8084/',
//     API_BASE_URL : 'http://192.168.0.71:8085/',
//     myToken: localStorage.getItem("token"),
//     myId: localStorage.getItem("userId"), 
//     HRMSDateFormat: "MMM DD, YYYY"
//       }

      export const api = {
        // API_BASE_URL : 'http://192.168.0.71:8085/', 
        API_BASE_URL : process.env.REACT_APP_API_BASE_URL,
        myToken: localStorage.getItem("token"),
        myId: localStorage.getItem("userId"),
        HRMSDateFormat: "DD-MM-YYYY"
    }