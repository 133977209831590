import React, { useState, useEffect } from 'react';
import { HeadingFour, MyDiv } from "../Common/Components";
import { Row, Col, Card, } from "react-bootstrap";
import { TextField, MenuItem } from '@mui/material';
import axios from 'axios';
import { TicketHandoverTeam } from '../Common/Constants/staticjson';
import swal from "sweetalert2";
import { api } from "../Common/Constants/apiurl";
import moment from 'moment';


const API_BASE_URL = api.API_BASE_URL;

function EstimateForm({ handleClose, projectID, editMode, deptData }) {
const API_TOKEN = localStorage.getItem("token");
    // console.log('deptName', deptData)
    const [data, setData] = useState({
        department: "",
        estimation_hours: "",
        planned_start_date: null,
        actual_start_date: null,
        planned_end_date: null,
        actual_end_date: null,
        notes: ""
    })

    useEffect(() => {
        loadResource();

        if (editMode) {
            setData(deptData);
        }
    }, []);

    const [resourceData, setresourceData] = useState([]);

    const loadResource = async () => {
        var url = API_BASE_URL + `fetchProjectResource`;
        axios.get(url, { headers: { 'x-access-token': `${API_TOKEN}`, 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(res => {
                setresourceData(res.data)
            },
                (error) => {
                    swal.fire({
                        text: error.response.data,
                        icon: "error",
                        type: "error"
                    });
                })
    }
    function handle(e) {
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value
        setData(newdata)
    }
    const handleChangeWhiteSpace = (e) => {
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value;
        setData(newdata)
    };


    function submit(e) {
        console.log('data', data)
        e.preventDefault();
        var url = API_BASE_URL + `update-project-department-details/${projectID}`;
        var method = 'patch';
        axios({ method, url, data, headers: { 'x-access-token': `${API_TOKEN}`, 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.status === 200) {
                    swal.fire({
                        text: "Successfully Saved",
                        icon: "success",
                        type: "success"
                    });
                    handleClose();
                }
            })
            .catch(error => {
                swal.fire({
                    text: error.response.data,
                    icon: "error",
                    type: "error"
                });
            });
    }

    return (
        <MyDiv className="DrawerRight">
            <MyDiv className="grid-margin general-form stretch-card">
                <Card md={12}>
                    <Card.Header>
                        <HeadingFour className="card-title">
                            {/* Manage {data.estimation_Department} Estimation */}
                        </HeadingFour>
                    </Card.Header>
                    <Card.Body>
                        <MyDiv className="DrawerForm">
                            <form onSubmit={(e) => submit(e)}>
                                <Row className="DrawerFormField">
                                    <Col md={12}>
                                        <TextField disabled required id="department" label="Department Name" value={data.department} variant="standard" onChange={(e) => handleChangeWhiteSpace(e)} />
                                    </Col>
                                    <Col md={12}>
                                        <TextField required id="estimation_hours" label="Estimation Hours" value={data.estimation_hours} variant="standard" onChange={(e) => handleChangeWhiteSpace(e)} />
                                    </Col>
                                    {data.department !== "ProjectManagement" ? <>
                                        <Col md={12}>
                                            <TextField id="planned_start_date" label="Planned Start Date and Time" type="datetime-local" value={data.planned_start_date || ""} sx={{ width: 220 }} onChange={(e) => handle(e)} InputLabelProps={{ shrink: true, }} variant="standard" />
                                        </Col>
                                        <Col md={12}>
                                            <TextField id="planned_end_date" label="Planned End Date and Time" inputProps={{}} type="datetime-local" value={data.planned_end_date || ""} sx={{ width: 220 }} onChange={(e) => handle(e)} InputLabelProps={{ shrink: true, }} variant="standard" />
                                        </Col>
                                        <Col md={12}>
                                            <TextField id="actual_start_date" label="Actual Start Date and Time" type="datetime-local" value={data.actual_start_date || ""} sx={{ width: 220 }} onChange={(e) => handle(e)} InputLabelProps={{ shrink: true, }} variant="standard" />
                                        </Col>
                                        <Col md={12}>
                                            <TextField id="actual_end_date" label="Actual End Date and Time" inputProps={{}} type="datetime-local" value={data.actual_end_date || ""} sx={{ width: 220 }} onChange={(e) => handle(e)} InputLabelProps={{ shrink: true, }} variant="standard" />
                                        </Col> </>
                                        : null}
                                    <Col md={12}>
                                        <TextField id="notes" multiline rows={4} label="Notes" value={data.notes} variant="standard" onChange={(e) => handleChangeWhiteSpace(e)} />
                                    </Col>
                                    <Col md={12} className="text-end">
                                        <button className="FormBtn">Submit</button>
                                    </Col>
                                </Row>
                            </form>
                        </MyDiv>
                    </Card.Body>
                </Card>
            </MyDiv>
        </MyDiv>
    );
}
export default EstimateForm;