import React, { useState, useEffect } from 'react';
import { TableBody, Table, TableContainer, TableHead, TableRow, TextField, TableCell, IconButton, Tooltip, Drawer, MenuItem, Button } from "@mui/material";
import { Row, Col, Card } from "react-bootstrap";
import { SpanTag, LabelTag, MyDiv, HeadingThree, HeadingFour } from "../Common/Components";
import { api } from '../Common/Constants/apiurl';
import axios from 'axios';
import swal from "sweetalert2";
import NoDataFound from '../Common/nodatafound';
import moment from 'moment';

const API_BASE_URL = api.API_BASE_URL;
const HRMSDateFormat = api.HRMSDateFormat;
export default function ProjectNotes (projectId) {
    const API_TOKEN = localStorage.getItem("token");
    const [notes, setNotes] = useState([]);
    const  [projectNotes, setProjectNotes] = useState("");
    const projectID = projectId.projectId;
    const addNotes = (event) => {
        setProjectNotes(event.target.value);
      };

      const getProjectNotes = async () => {
        try {
            var url = API_BASE_URL + `fetch-notes/${projectID}`;
            await axios.get(url, { headers: { 'x-access-token': `${API_TOKEN}`, 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(response => {
                setNotes(response.data.pm_pro_notes);
                console.log("received notes", response.data);
            })
        } catch (error) {
            swal.fire({
                text: error.response?.data || "Error fetching resources",
                icon: "error",
            });
        }
      };
      useEffect(() => {
        getProjectNotes()
      }, [])

    const submitHandler = async (e) => {
        e.preventDefault();
        console.log("submitHandler called")
        var url = API_BASE_URL + `add-notes/${projectID}`;
        try {
            const data = {notes: projectNotes}
            const response = await axios.post(url, data, { headers: { 'x-access-token': `${API_TOKEN}`, 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(res => {
                getProjectNotes();
                swal.fire({
                    text: res.data,
                    icon: "success",
                    type: "success"
                  })
            })
        } catch (error) {
            console.log(error);
            swal.fire({
                text: error.response?.data || "Error fetching notes",
                icon: "error",
            });
        }
    };

    return(
        <Card className="LayoutCard">
            <Card.Header>
                <HeadingFour>Project Notes</HeadingFour>
            </Card.Header>
            <Card.Body>
              <Row>
                    <Col md={12}>
                    <MyDiv className="GeneralTable pxy-0 mt-0 ">
                    <TableContainer>
                    <Table className='bgGrey mt-0' aria-label="Milestone table">
                    <TableHead>
                    <TableRow>
                        <TableCell align="left">Date</TableCell>
                        <TableCell align="left">Notes</TableCell>
                    </TableRow>
                    </TableHead>
                        <TableBody>
                           {notes.length > 0 && notes.map(note => 
                        <React.Fragment key={note._id}>
                            <TableRow>
                            <TableCell>{moment(note?.created).format('MMM-DD-YYYY hh:mm a')}</TableCell>
                            <TableCell>{note?.notes}</TableCell>
                        </TableRow>
                        </React.Fragment>
                            )}
                        </TableBody>
                    </Table>
                    </TableContainer>
                    </MyDiv>
                    </Col>
                </Row>
       
                <form onSubmit={(e) => submitHandler(e)}>
            <Row>
                <Col md = {6}>
                <TextField required id="projectNotes" fullWidth InputLabelProps={{ shrink: true, }} label="Add Notes" value={projectNotes} variant="outlined" onChange={(e) => addNotes(e)} />
                </Col>
                <Col md = {6}>
                <Button className='btn primary-btn mt-2' type='submit'>Add Note</Button>
                </Col>
            </Row>
                </form>
            </Card.Body>
        </Card>
    )
}