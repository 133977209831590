import React, { useEffect } from 'react';
import { Sidebar, Menu, MenuItem, useProSidebar, SubMenu } from 'react-pro-sidebar';
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../Common/header";
import { MyDiv, PTag } from "../Common/Components";
import { AiFillDashboard, AiOutlineUser, AiFillSetting, AiOutlineFundProjectionScreen, AiFillMinusCircle } from "react-icons/ai";
import { HiUserGroup } from "react-icons/hi";
import { BsCashCoin } from "react-icons/bs";
import { GiNotebook, GiChessKing, GiHistogram } from "react-icons/gi";
import { MdHolidayVillage, MdWifiProtectedSetup,MdHomeWork } from "react-icons/md";
import { VscFileSubmodule } from "react-icons/vsc";
import { TbAsset, TbStack3 } from "react-icons/tb";
import {FiUserCheck} from "react-icons/fi";
import { FaSchool, FaTasks, FaProjectDiagram,FaRegCalendarTimes,FaDoorOpen } from "react-icons/fa";
import { BiSitemap, BiMenu, BiCalendar } from "react-icons/bi";
import { TbReceiptTax } from "react-icons/tb";
import { Button } from "react-bootstrap";



function TemplateAudit() {
    const [module, setModule] = React.useState([{
        Masters: '',
    }
    ])

    const navigate = useNavigate();
    const { collapseSidebar } = useProSidebar();
    const pathname = useLocation().pathname;

    useEffect(() => {
        async function init() {
            const data = await localStorage.getItem('role');

            setModule(JSON.parse(data));
        }
        init();
        if ((localStorage.getItem("token") === null) && (localStorage.getItem(" ") === null)) {
            navigate("/login");
        }
    }, [navigate]);


    return (
        <div>
            <Header />
            <MyDiv className="GeneralContainer">
                <MyDiv className="LeftSideContainer">
                    <Button className="Sidebarcollapse" onClick={() => collapseSidebar()}>
                        <BiMenu />
                    </Button>
                    <Sidebar>
                        <Menu>
                            <MenuItem Tooltip title="Dashboard" className={`${pathname === 'audit/dashboard' ? "menuActive" : ""}`} icon={<AiFillDashboard />} component={<Link to="audit/dashboard" />}>Dashboard</MenuItem>
                          
                        </Menu>
                    </Sidebar>
              

                </MyDiv>
                <MyDiv className="RightMainContainer">
                    <MyDiv className="Right-Side-Main-Container">
                        <Outlet />
                    </MyDiv>
                    <MyDiv className="footer-copyrights ">
                        <PTag className="text-center mt-15">{(new Date().getFullYear())} @ SNS. All rights reserved</PTag>
                    </MyDiv>
                </MyDiv>

            </MyDiv>
        </div>
    );
}

export default TemplateAudit;